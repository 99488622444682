import { useState, useEffect } from "react";
import { API_URL } from "../../config";
import Swal from "sweetalert2";

//icons
import { FiRefreshCcw } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { FaEdit, FaTrash } from "react-icons/fa";

//components
import DataTable from "../datatable/DataTable";
import AddSuscriptores from "./modals/AddSuscriptores";
import EditSuscriptores from "./modals/EditSuscriptores";

const Suscriptores = () => {
  const [suscriptores, setSuscriptores] = useState(false);
  const [selectedSub, setSelectedSub] = useState({
    id: "",
    mes_corte: "",
    nombre_organizacion: "",
    razon_social: "",
    macro_inicial: "",
    valor_metro_cubico: "",
    tope_subsidio: "",
    costo_fijo: "",
    datos_contacto: "",
    datos_pago: "",
    estado_suscripcion: 1,
    nivel_suscripcion: 1,
  });

  const tableHeaders = [
    "id",
    "nombre_organizacion",
    "mes_corte",
    "razon_social",
    "nivel_suscripcion",
    "estado_suscripcion",
    "costo_fijo",
    "acciones",
  ];

  const editHandler = async (id) => {
    //form data
    const formData = new FormData();
    formData.append("api_function", "fetchSuscriptoresById");
    formData.append("id", id);

    //send form data
    const res = await fetch(`${API_URL}/suscriptores.php`, {
      method: "POST",
      body: formData,
    });

    //parse response
    const data = await res.json();

    setSelectedSub((prev) => {
      return { ...prev, ...data[0] };
    });
  };

  const deleteHandler = async (id) => {
    //form data
    const formData = new FormData();
    formData.append("api_function", "deleteSuscriptores");
    formData.append("id", id);

    const prompt = await Swal.fire({
      icon: "warning",
      title: "Desea eliminar este registro de manera permanente?",
      showCancelButton: true,
      confirmButtonText: "Eliminar registro",
      cancelButtonText: "Cancelar",
    });

    if (prompt.isConfirmed) {
      const response = await fetch(`${API_URL}/suscriptores.php`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.status === "ok") {
        Swal.fire({
          icon: "success",
          title: "Bien!",
          text: data.message,
        }).then(() => {
          fetchSuscriptores();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: data.message,
        });
      }
    }
  };

  const fetchSuscriptores = async () => {
    //form data
    const formData = new FormData();
    formData.append("api_function", "fetchSuscriptores");

    //send form data
    const res = await fetch(`${API_URL}/suscriptores.php`, {
      method: "POST",
      body: formData,
    });

    //parse response
    const data = await res.json();

    //parse dataSet
    const dataSet = data.map((suscriptor) => {
      return {
        id: suscriptor.id,
        nombre_organizacion: suscriptor.nombre_organizacion,
        mes_corte: suscriptor.mes_corte,
        razon_social: suscriptor.razon_social,
        nivel_suscripcion: suscriptor.nivel_suscripcion,
        estado_suscripcion:
          suscriptor.estado_suscripcion === "1" ? "Activo" : "Suspendido",
        costo_fijo: suscriptor.costo_fijo,
        acciones: (
          <div className="btn-group">
            <button
              key="edit"
              className="btn btn-info"
              onClick={() => editHandler(suscriptor.id)}
              data-bs-toggle="modal"
              data-bs-target="#modal-edit-suscriptores"
            >
              <FaEdit />
            </button>
            <button
              key="delete"
              className="btn btn-danger"
              onClick={() => deleteHandler(suscriptor.id)}
            >
              <FaTrash />
            </button>
          </div>
        ),
      };
    });

    //table rows
    setSuscriptores(dataSet);
  };

  const tableButtons = [
    <button
      key="add"
      className="btn btn-outline-success"
      data-bs-toggle="modal"
      data-bs-target="#modal-add-suscriptores"
    >
      <IoMdAdd /> Añadir
    </button>,
    <button
      key="reload"
      className="btn btn-outline-secondary"
      onClick={fetchSuscriptores}
    >
      <FiRefreshCcw /> Recargar
    </button>,
  ];

  useEffect(() => {
    fetchSuscriptores();
  }, []);

  return (
    <div className="container">
      <h1 className="mb-3">Suscriptores</h1>

      {suscriptores && (
        <>
          <DataTable
            tableButtons={tableButtons}
            rows={suscriptores}
            headers={tableHeaders}
          />
          <AddSuscriptores onNewData={fetchSuscriptores} />
          <EditSuscriptores
            onNewData={fetchSuscriptores}
            suscriptor={selectedSub}
          />
        </>
      )}
    </div>
  );
};

export default Suscriptores;
