import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as bootstrap from "bootstrap"; /* initalize bootstrap components */
import { API_URL } from "./config";

/* components */
import Header from "./components/UI/Header";
import Login from "./components/login/Login";
import Suscriptores from "./components/suscriptores/Suscriptores";
import Usuarios from "./components/usuarios/Usuarios";
import Home from "./components/home/Home";

function App() {
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    console.log("checking authentication");
    const formData = new FormData();
    formData.append("api_function", "fetchAuth");
    fetch(`${API_URL}/session.php`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCheckingAuth(false);
        if (data.isAuthenticated) {
          setIsAuthenticated(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setCheckingAuth(false);
      });
  }, [isAuthenticated]);

  const loginHandler = () => {
    setIsAuthenticated(true);
  };

  const logoutHandler = () => {
    const formData = new FormData();
    formData.append("api_function", "logoutAdmin");
    fetch(`${API_URL}/session.php`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((logout) => {
        if (logout.isAuthenticated === false) {
          setIsAuthenticated(false);
        }
      });
  };

  return (
    <div className="App">
      {checkingAuth && (
        <h1
          style={{
            position: "fixed",
            top: "40%",
            width: "100%",
            textAlign: "center",
          }}
        >
          Cargando...
        </h1>
      )}
      {isAuthenticated && !checkingAuth && (
        <>
          <Header logoutHandler={logoutHandler} />
          <main className="app-main">
            <Routes>
              <Route index element={<Home />} />
              <Route path="/suscriptores" element={<Suscriptores />} />
              <Route path="/usuarios" element={<Usuarios />} />
            </Routes>
          </main>
        </>
      )}
      {!isAuthenticated && !checkingAuth && (
        <Login loginHandler={loginHandler} />
      )}
    </div>
  );
}

export default App;
