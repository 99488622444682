const FormInput = ({ label, id, properties }) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <input id={id} className="form-control mt-1" {...properties} />
    </>
  );
};

export default FormInput;
