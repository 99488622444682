const FormTextArea = ({ label, id, properties }) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <textarea id={id} className="form-control" {...properties}></textarea>
    </>
  );
};

export default FormTextArea;
