import React, { useState } from "react";
import APRLogo from "../../assets/img/logo_small.png";
import { API_URL } from "../../config";
import Swal from "sweetalert2";
import "./Login.css";

const Login = (props) => {
  const [formData, setFormData] = useState({
    admin_user: "",
    admin_password: "",
  });

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const loginHandler = (e) => {
    e.preventDefault();

    const loginData = new FormData(e.target);
    loginData.append("api_function", "authAdmin");

    fetch(`${API_URL}/session.php`, {
      method: "POST",
      body: loginData,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.isAuthenticated) {
          props.loginHandler();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: data.message,
          });
        }
      });
  };

  return (
    <div className="login-screen">
      <div className="container">
        <div className="row justify-content-center px-3">
          <div className="col-md-6 card shadow pb-5 pt-3 ">
            <div className="card-body">
              <img src={APRLogo} alt="logo" className="logo" />
              <form onSubmit={loginHandler}>
                <div className="mb-3">
                  <label htmlFor="admin_user" className="form-label text-start">
                    User
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="admin_user"
                    name="admin_user"
                    value={formData.admin_user}
                    onChange={inputChangeHandler}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="admin_password"
                    className="form-label text-start"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="admin_password"
                    name="admin_password"
                    value={formData.admin_password}
                    onChange={inputChangeHandler}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-secondary w-100">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
