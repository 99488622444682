import { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import "./DataTable.css";

/* 
  @props:
    rows: array of objects, each object is a table row
    headers: array of strings, each array index string is a table header
*/
const DataTable = (props) => {
  /* table rows */
  const data = props.rows;
  /* table headers */
  const headers = props.headers;

  // search filter
  const [searchFilter, setSearchFilter] = useState("");

  // State variables for pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [itemsPerPage, setItemsPerPage] = useState(10); // Items per page

  const searchChangeHandler = (event) => {
    setSearchFilter(event.target.value);
    setCurrentPage(1);
  };

  const filteredItems = data.filter((row) => {
    // Filter rows based on search query
    const values = Object.values(row);
    return values.some((value) => {
      const cellValue = value.toString().toLowerCase();
      // Check if the cell value includes the search query or if it's a partial number match
      return (
        cellValue.includes(searchFilter.toLowerCase()) ||
        cellValue.replace(/[^0-9]/g, "").includes(searchFilter)
      );
    });
  });

  // Calculate the index range for items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Total number of pages
  const totalPageCount = Math.ceil(filteredItems.length / itemsPerPage);

  const previousPageHandler = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPageHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  const itemsPerPageHandler = (event) => {
    setItemsPerPage(event.target.value);
  };

  return (
    <div className="card shadow">
      <div className="card-body">
        <div className="table-responsive">
          <div className="container-fluid">
            <div className="row">
              {/* table buttons */}
              <div className="col-12 px-0 mb-3">
                <div className="btn-group">
                  {props.tableButtons.length > 0 &&
                    props.tableButtons.map((btn) => btn)}
                </div>
              </div>

              {/* pagination amount selector */}
              <div className="col-6 mb-3 ps-1 text-start">
                <span>Mostrar</span>
                <select
                  className="form-select pagination-amount-selector"
                  onChange={itemsPerPageHandler}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span>resultados por pagína</span>
              </div>

              {/* search filter */}
              <div className="col-12 col-md-6 px-0 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar..."
                  value={searchFilter}
                  onChange={searchChangeHandler}
                />
              </div>
            </div>
          </div>

          {/* table */}
          <table className="table table-hover">
            <thead>
              <tr>
                {/* table headers */}
                {headers.map((header, index) => (
                  <th scope="col" key={index} className="text-capitalize">
                    {header.replace("_", " ")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* no results message */}
              {currentItems.length === 0 && (
                <tr>
                  <td colSpan={headers.length} className="text-center py-5">
                    <h3>No hay registros para mostrar</h3>
                  </td>
                </tr>
              )}

              {/* print table rows */}
              {currentItems.map((row, index) => {
                const tr = (
                  <tr key={index}>
                    {Object.keys(row).map((key, index) => {
                      return <td key={index}>{row[key]}</td>;
                    })}
                  </tr>
                );
                return tr;
              })}
            </tbody>
          </table>

          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <p>
                  Mostrando{" "}
                  <strong>
                    {currentPage === 1
                      ? 1
                      : itemsPerPage * (currentPage - 1) + 1}
                  </strong>{" "}
                  a{" "}
                  <strong>
                    {currentPage * itemsPerPage < filteredItems.length
                      ? currentPage * itemsPerPage
                      : filteredItems.length}
                  </strong>{" "}
                  de
                  <strong> {filteredItems.length}</strong> resultados
                </p>
              </div>
              <div className="col-6 text-end">
                <div className="btn-group">
                  {/* previous page */}
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={previousPageHandler}
                    disabled={currentPage === 1}
                  >
                    <AiOutlineLeft />
                  </button>

                  {/* pages by number */}
                  {Array.from({ length: totalPageCount }, (_, index) => (
                    <button
                      key={index}
                      type="button"
                      className={`btn btn-outline-secondary ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}

                  {/* next page */}
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={nextPageHandler}
                    disabled={currentPage === totalPageCount}
                  >
                    <AiOutlineRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DataTable.defaultProps = {
  tableButtons: [],
};

export default DataTable;
