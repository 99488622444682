const FormSelect = ({ label, id, properties, options }) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <select id={id} className="form-select mt-1" {...properties}>
        {options.map((option) => {
          return (
            <option
              value={option.value}
              defaultValue={option.selected}
              key={option.value}
            >
              {option.name}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default FormSelect;
