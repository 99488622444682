import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import FormInput from "../../forms/FormInput";
import FormSelect from "../../forms/FormSelect";
import FormTextArea from "../../forms/FormTextArea";

const EditSuscriptores = (props) => {
  const modal = useRef();
  const form = useRef();

  const [inputValues, setInputValues] = useState({
    id: props.suscriptor.id,
    mes_corte: props.suscriptor.mes_corte,
    nombre_organizacion: props.suscriptor.nombre_organizacion,
    razon_social: props.suscriptor.razon_social,
    macro_inicial: props.suscriptor.macro_inicial,
    valor_metro_cubico: props.suscriptor.valor_metro_cubico,
    tope_subsidio: props.suscriptor.tope_subsidio,
    costo_fijo: props.suscriptor.costo_fijo,
    datos_contacto: props.suscriptor.datos_contacto,
    datos_pago: props.suscriptor.datos_pago,
    estado_suscripcion: props.suscriptor.estado_suscripcion,
    nivel_suscripcion: props.suscriptor.nivel_suscripcion,
  });

  useEffect(() => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      id: props.suscriptor.id,
      mes_corte: props.suscriptor.mes_corte,
      nombre_organizacion: props.suscriptor.nombre_organizacion,
      razon_social: props.suscriptor.razon_social,
      macro_inicial: props.suscriptor.macro_inicial,
      valor_metro_cubico: props.suscriptor.valor_metro_cubico,
      tope_subsidio: props.suscriptor.tope_subsidio,
      costo_fijo: props.suscriptor.costo_fijo,
      datos_contacto: props.suscriptor.datos_contacto,
      datos_pago: props.suscriptor.datos_pago,
      estado_suscripcion: props.suscriptor.estado_suscripcion,
      nivel_suscripcion: props.suscriptor.nivel_suscripcion,
    }));
  }, [props.suscriptor]);

  /* modal events */
  useEffect(() => {
    /* on closing modal */
    modal.current &&
      modal.current.addEventListener("hidden.bs.modal", props.onClose);
  }, []);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    /* form data */
    const formData = new FormData(event.target);
    formData.append("api_function", "updateSuscriptores");
    formData.append("id", inputValues.id);
    /* send form to server */
    fetch(`${API_URL}/suscriptores.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: "Bien!",
          text: data.message,
        }).then(() => {
          props.onNewData();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id="modal-edit-suscriptores"
      ref={modal}
    >
      <div className="modal-dialog modal-fullscreen">
        <form onSubmit={formSubmitHandler} className="modal-content" ref={form}>
          <div className="modal-header shadow-sm">
            <h4 className="modal-title text-primary">Agregar Suscriptores</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body py-4">
            <div className="card shadow">
              <div className="card-header bg-transparent">
                <h4>Datos del suscriptor</h4>
              </div>
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-12 col-lg-6 mb-3">
                    <FormInput
                      id="nombre_organizacion"
                      label="Nombre Organización"
                      properties={{
                        type: "text",
                        name: "nombre_organizacion",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["nombre_organizacion"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-6 mb-3">
                    <FormInput
                      id="razon_social"
                      label="Razón Social"
                      properties={{
                        type: "text",
                        name: "razon_social",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["razon_social"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-3 mb-3">
                    <FormInput
                      id="macro_inicial"
                      label="Macro Inicial"
                      properties={{
                        type: "number",
                        name: "macro_inicial",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["macro_inicial"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-3 mb-3">
                    <FormInput
                      id="mes_corte"
                      label="Mes de Corte"
                      properties={{
                        type: "number",
                        name: "mes_corte",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["mes_corte"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-3 mb-3">
                    <FormInput
                      id="valor_metro_cubico"
                      label="Valor metro cubico"
                      properties={{
                        type: "number",
                        name: "valor_metro_cubico",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["valor_metro_cubico"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-3 mb-3">
                    <FormInput
                      id="costo_fijo"
                      label="Costo Fijo"
                      properties={{
                        type: "number",
                        name: "costo_fijo",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["costo_fijo"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-3 mb-3">
                    <FormInput
                      id="tope_subsidio"
                      label="Tope Subsidio (%)"
                      properties={{
                        type: "number",
                        name: "tope_subsidio",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["tope_subsidio"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-3 mb-3">
                    <FormSelect
                      id="nivel_suscripcion"
                      label="Nivel Suscripción"
                      properties={{
                        name: "nivel_suscripcion",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["nivel_suscripcion"],
                      }}
                      options={[
                        {
                          value: 1,
                          name: "Nivel 1",
                        },
                        {
                          value: 2,
                          name: "Nivel 2",
                        },
                      ]}
                    />
                  </div>

                  <div className="col-12 col-lg-6 mb-3">
                    <FormSelect
                      id="estado_suscripcion"
                      label="Estado Suscripción"
                      properties={{
                        name: "estado_suscripcion",
                        required: true,
                        onChange: inputChangeHandler,
                        value: inputValues["estado_suscripcion"],
                      }}
                      options={[
                        {
                          value: 1,
                          name: "Activo",
                        },
                        {
                          value: 0,
                          name: "Suspendido",
                        },
                      ]}
                    />
                  </div>

                  <div className="col-12 col-lg-6 mb-3">
                    <FormTextArea
                      label="Datos de Contacto"
                      id="datos_contacto"
                      properties={{
                        name: "datos_contacto",
                        rows: "3",
                        onChange: inputChangeHandler,
                        value: inputValues["datos_contacto"],
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-6 mb-3">
                    <FormTextArea
                      label="Datos de Pago"
                      id="datos_pago"
                      properties={{
                        name: "datos_pago",
                        rows: "3",
                        onChange: inputChangeHandler,
                        value: inputValues["datos_pago"],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button type="submit" className="btn btn-primary">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSuscriptores;
