import "./Header.css";
import { NavLink } from "react-router-dom";
import APRLogo from "../../assets/img/logo_small.png";
import { API_URL } from "../../config";

const HeaderComponent = (props) => {
  return (
    <nav
      className="navbar navbar-expand-md navbar-light"
      style={{ boxShadow: "0 0 .6rem rgba(0,0,0, .175)" }}
    >
      <div className="container d-flex">
        <NavLink className="navbar-brand" aria-current="page" to="/">
        <img src={APRLogo} alt="logo" height="48" />
        </NavLink>
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item pe-1">
              <NavLink
                className="btn btn-nav"
                aria-current="page"
                to="/suscriptores"
              >
                Suscriptores
              </NavLink>
            </li>
            <li className="nav-item pe-1">
              <NavLink
                className="btn btn-nav"
                aria-current="page"
                to="/usuarios"
              >
                Usuarios
              </NavLink>
            </li>
            <li className="nav-item pe-1">
              <button className="btn btn-nav" onClick={props.logoutHandler}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HeaderComponent;
