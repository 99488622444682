import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import FormInput from "../../forms/FormInput";
import FormSelect from "../../forms/FormSelect";

const AddUsuarios = (props) => {
  const modal = useRef();
  const form = useRef();

  const [inputValues, setInputValues] = useState({
    id: "",
    nombre: "",
    email: "",
    pass: "",
    user_role: "",
    id_suscriptor: "",
  });

  useEffect(() => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,  
      id: props.usuario.id,
      nombre: props.usuario.nombre,
      email: props.usuario.email,
      pass: props.usuario.pass,
      user_role: props.usuario.user_role,
      id_suscriptor: props.usuario.id_suscriptor,
    }));
  }, [props.usuario]);

  /* modal events */
  useEffect(() => {
    /* on closing modal */
    modal.current &&
      modal.current.addEventListener("hidden.bs.modal", props.onClose);
  }, []);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    /* form data */
    const formData = new FormData(event.target);
    formData.append("api_function", "updateUsuarios");
    formData.append("id", inputValues.id);
    /* send form to server */
    fetch(`${API_URL}/usuarios.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: "Bien!",
          text: data.message,
        }).then(() => {
          props.onNewData();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id="modal-edit-usuarios"
      ref={modal}
    >
      <div className="modal-dialog modal-fullscreen">
        <form onSubmit={formSubmitHandler} className="modal-content" ref={form}>
          <div className="modal-header shadow-sm">
            <h4 className="modal-title text-primary">Agregar Usuarios</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body py-4">
            <div className="card shadow">
              <div className="card-header bg-transparent">
                <h4>Datos del usuario</h4>
              </div>
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-12 col-lg-6 mb-3">
                    <FormInput
                      id="nombre"
                      label="Nombre Completo"
                      properties={{
                        type: "text",
                        name: "nombre",
                        required: true,
                        value: inputValues["nombre"],
                        onChange: inputChangeHandler,
                      }}
                    />
                  </div>
                  <div className="col-12 col-lg-6 mb-3">
                    <FormInput
                      id="email"
                      label="Email"
                      properties={{
                        type: "email",
                        name: "email",
                        required: true,
                        value: inputValues["email"],
                        onChange: inputChangeHandler,
                      }}
                    />
                  </div>
                  <div className="col-12 col-lg-6 mb-3">
                    <FormInput
                      id="pass"
                      label="Password"
                      properties={{
                        type: "text",
                        name: "pass",
                        required: true,
                        value: inputValues["pass"],
                        onChange: inputChangeHandler,
                      }}
                    />
                  </div>
                  <div className="col-12 col-lg-6 mb-3">
                    <FormSelect
                      id="user_role"
                      label="Rol Usuario"
                      properties={{
                        name: "user_role",
                        required: true,
                        value: inputValues["user_role"],
                        onChange: inputChangeHandler,
                      }}
                      options={[
                        { value: "", name: "Seleccione rol de usuario" },
                        { value: 1, name: "Administrador" },
                        { value: 2, name: "Digitador" },
                      ]}
                    />
                  </div>
                  <div className="col-12 col-lg-6 mb-3">
                    <FormSelect
                      id="id_suscriptor"
                      label="Suscriptor"
                      properties={{
                        name: "id_suscriptor",
                        required: true,
                        value: inputValues["id_suscriptor"],
                        onChange: inputChangeHandler,
                      }}
                      options={[
                        { value: "", name: "Seleccione Suscriptor" },
                        ...props.suscriptores,
                      ]}
                    />
                  </div>
                </div>
                {/* row */}
              </div>
              {/* card-body */}
            </div>
            {/* card */}
          </div>
          {/* modal-body */}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button type="submit" className="btn btn-primary">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUsuarios;
