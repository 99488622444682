import { useState, useEffect } from "react";
import { API_URL } from "../../config";
import Swal from "sweetalert2";

//icons
import { FiRefreshCcw } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { FaEdit, FaTrash } from "react-icons/fa";

//components
import DataTable from "../datatable/DataTable";
import AddUsuarios from "./modals/AddUsuarios";
import EditUsuarios from "./modals/EditUsuarios";

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState(false);
  const [selectedUser, setselectedUser] = useState({
    id: "",
    nombre: "",
    email: "",
    pass: "",
    user_role: "",
    id_suscriptor: "",
  });

  const tableHeaders = [
    "id",
    "suscriptor",
    "nombre",
    "email",
    "rol_usuario",
    "acciones",
  ];

  const [suscriptores, setSuscriptores] = useState([]);

  const editHandler = async (id) => {
    //form data
    const formData = new FormData();
    formData.append("api_function", "fetchUsuariosById");
    formData.append("id", id);

    //send form data
    const res = await fetch(`${API_URL}/usuarios.php`, {
      method: "POST",
      body: formData,
    });

    //parse response
    const data = await res.json();

    setselectedUser((prev) => {
      return { ...prev, ...data[0] };
    });
  };

  const deleteHandler = async (id) => {
    //form data
    const formData = new FormData();
    formData.append("api_function", "deleteUsuarios");
    formData.append("id", id);

    const prompt = await Swal.fire({
      icon: "warning",
      title: "Desea eliminar este registro de manera permanente?",
      showCancelButton: true,
      confirmButtonText: "Eliminar registro",
      cancelButtonText: "Cancelar",
    });

    if (prompt.isConfirmed) {
      const response = await fetch(`${API_URL}/usuarios.php`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      console.log(data);

      if (data === 200) {
        Swal.fire({
          icon: "success",
          title: "Bien!",
          text: 'Se eliminó el usuario',
        }).then(() => {
          fetchUsuarios();
          fetchSuscriptores();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: data,
        });
      }
    }
  };

  const fetchUsuarios = async () => {
    //form data
    const formData = new FormData();
    formData.append("api_function", "fetchUsuarios");

    //send form data
    const res = await fetch(`${API_URL}/usuarios.php`, {
      method: "POST",
      body: formData,
    });

    //parse response
    const data = await res.json();

    //parse dataSet
    const dataSet = data.map((usuario) => {
      return {
        id: usuario.id,
        suscriptor: usuario.suscriptor[0].nombre_organizacion,
        nombre: usuario.nombre,
        email: usuario.email,
        rol_usuario: usuario.user_role === "1" ? "Administrador" : "Digitador",
        acciones: (
          <div className="btn-group">
            <button
              key="edit"
              className="btn btn-info"
              onClick={() => editHandler(usuario.id)}
              data-bs-toggle="modal"
              data-bs-target="#modal-edit-usuarios"
            >
              <FaEdit />
            </button>
            <button
              key="delete"
              className="btn btn-danger"
              onClick={() => deleteHandler(usuario.id)}
            >
              <FaTrash />
            </button>
          </div>
        ),
      };
    });

    //set table rows State
    setUsuarios((prevUsers) => {
      return dataSet;
    });
  };

  const tableButtons = [
    <button
      key="add"
      className="btn btn-outline-success"
      data-bs-toggle="modal"
      data-bs-target="#modal-add-usuarios"
    >
      <IoMdAdd /> Añadir
    </button>,
    <button
      key="reload"
      className="btn btn-outline-secondary"
      onClick={() => {
        fetchUsuarios();
        fetchSuscriptores();
      }}
    >
      <FiRefreshCcw /> Recargar
    </button>,
  ];

  const fetchSuscriptores = async () => {
    //form data
    const formData = new FormData();
    formData.append("api_function", "fetchSuscriptores");

    //send form data
    const res = await fetch(`${API_URL}/suscriptores.php`, {
      method: "POST",
      body: formData,
    });

    //parse response
    const data = await res.json();

    //parse dataSet
    const dataSet = data.map((suscriptor) => {
      return {
        value: suscriptor.id,
        name: suscriptor.nombre_organizacion,
      };
    });

    //table rows
    setSuscriptores(dataSet);
  };

  useEffect(() => {
    fetchUsuarios();
    fetchSuscriptores();
  }, []);

  return (
    <div className="container">
      <h1 className="mb-3">Usuarios</h1>

      {usuarios && (
        <>
          <DataTable
            tableButtons={tableButtons}
            rows={usuarios}
            headers={tableHeaders}
          />
          <AddUsuarios onNewData={fetchUsuarios} suscriptores={suscriptores} />
          <EditUsuarios
            onNewData={fetchUsuarios}
            suscriptores={suscriptores}
            usuario={selectedUser}
          />
        </>
      )}
    </div>
  );
};

export default Usuarios;
